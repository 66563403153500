<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <div class="page-title">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <h2 class="ipt-title">Pricing</h2>
              <span class="ipn-subtitle">Overview</span>
            </div>
          </div>
        </div>
      </div>

      <section v-if="loaded">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-10 text-center">
              <div class="sec-heading center">
                <h2>See our packages</h2>
                <p>Affordable pricing to meet your needs</p>
              </div>
            </div>
          </div>

          <div class="table-responsive mt-3" v-for="(pricing, type) in pricings" :key="`pricings-${type}`">
            <h4>{{ type == 'listings' ? 'General Listings' : type == 'bnb' ? 'RealBnB' : type }} Packages</h4>
            <table class="table table-bordered">
              <thead class="text-center">
                <tr>
                  <th width="30%"></th>
                  <th
                    v-for="(price, i) in pricing"
                    :key="`thead-${i}`"
                    :class="`${
                      i == 1 ? 'basic' : i == 2 ? 'platinum' : 'standard'
                    }-pr`"
                    :width="`${70 / pricing.length}%`"
                  >
                    <div class="pricing-header">
                      <h4 class="pr-value" v-if="price.amount > 0">
                        <sup>KES</sup>{{ price.amount }}
                      </h4>
                      <h4 class="pr-value" v-if="price.amount == 0">Free</h4>
                      <h4 class="pr-title">{{ price.title }}</h4>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line, i) in getContentLines(pricing)" :key="`line-${i}`">
                  <td>{{ line }}</td>
                  <td
                    class="text-center"
                    v-for="(price, j) in pricing"
                    :key="`line-${j}`"
                  >
                    {{ price.content[line] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <loading v-if="!loaded" />

      <call-to-action />

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      pricings: [],
      loaded: false,
    };
  },

  methods: {
    fetch() {
      this.loaded = false;

      this.$axios.get("/api/v1/pricing").then((response) => {
        this.pricings = response.data.pricings;
        this.loaded = true;
      });
    },

    getContentLines(pricing) {
      return Object.keys(pricing[0].content);
    }
  },
};
</script>
